:root {
}
/* Cookie Dialog */
#gdpr-cookie-message {
	--purple: #3B3646;
	--red: #EE4B5A;
    position: fixed;
    /*left: 5vw;*/
    right: 30px;
    bottom: 30px;
    max-width: 375px;
    background-color: var(--purple);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 6px 6px rgba(0,0,0,0.25);
    margin-left: 30px;
	z-index: 10;
}
#gdpr-cookie-message h4 {
    color: var(--red);
    /*font-family: 'Quicksand', sans-serif;*/
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
#gdpr-cookie-message h5 {
    color: var(--red);
    /*font-family: 'Quicksand', sans-serif;*/
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
#gdpr-cookie-message p,
#gdpr-cookie-message ul {
    color: white;
    font-size: 15px;
    line-height: 1.5em;
}
#gdpr-cookie-message p:last-child {
    margin-bottom: 0;
    text-align: center;
}
#gdpr-cookie-message li {
    width: 49%;
    display: inline-block;
}
#gdpr-cookie-message a {
    color: var(--red);
    text-decoration: none;
    font-size: 15px;
    padding-bottom: 2px;
    border-bottom: 1px dotted rgba(255,255,255,0.75);
    transition: all 0.3s ease-in;
}
#gdpr-cookie-message a:hover {
    color: white;
    border-bottom-color: var(--red);
    transition: all 0.3s ease-in;
}
#gdpr-cookie-message button,
button#ihavecookiesBtn {
    border: none;
    background: var(--red);
    color: white;
    /*font-family: 'Quicksand', sans-serif;*/
    font-size: 15px;
    padding: 7px;
    border-radius: 3px;
    margin-left: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in;
}
#gdpr-cookie-message button#gdpr-cookie-accept {
	margin-left: 0;
}
#gdpr-cookie-message button:hover {
    background: white;
    color: var(--red);
    transition: all 0.3s ease-in;
}
button#gdpr-cookie-advanced {
    background: white;
    color: var(--red);
}
#gdpr-cookie-message button:disabled {
    opacity: 0.3;
}
#gdpr-cookie-message input[type="checkbox"] {
    float: none;
    margin-top: 0;
    margin-right: 5px;
}



@media screen and (max-width: 896px) and (orientation: landscape) ,
screen and (max-width: 768px) {
	#gdpr-cookie-message {
		left: 5vw;
		right: 5vw;
		max-width: none;
		width: 90vw;
		margin-left: 0;
	}
}