/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden; -webkit-transform: translate3d(0,0,0);}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#fff; opacity: 0.9; filter: alpha(opacity = 90);}
#colorbox{outline:0;}
    #cboxTopLeft{width:25px; height:25px; }
    #cboxTopCenter{height:25px}
    #cboxTopRight{width:25px; height:25px;}
    #cboxBottomLeft{width:25px; height:25px;}
    #cboxBottomCenter{height:25px;}
    #cboxBottomRight{width:25px; height:25px;}
    #cboxMiddleLeft{width:25px;}
    #cboxMiddleRight{width:25px;}

#cboxContent {
	background: #fff;
	overflow: hidden;
	box-shadow: 0 0 40px -20px black;
	/*border-radius: 1em;*/
	padding: 5px;
}
#cboxLoadedContent {
	scrollbar-color: var(--accent-color) transparent;
	scrollbar-width: 5px;
}
#cboxLoadedContent::-webkit-scrollbar {
	width: 0.3rem;
	position: fixed;
	transition: all .5s ease-in-out;
}
#cboxLoadedContent:hover::-webkit-scrollbar,
#cboxLoadedContent:focus::-webkit-scrollbar,
#cboxLoadedContent:focus-within::-webkit-scrollbar {
	width: 0.5rem;
}
#cboxLoadedContent::-webkit-scrollbar-track {
	background: transparent;
}
#cboxLoadedContent::-webkit-scrollbar-thumb {
	background: var(--accent-color);
}
        .cboxIframe{background:#fff;}
        #cboxError{padding: 50px; border: 1px solid #ccc;}
        #cboxLoadedContent{margin-bottom: 3em;}
        #cboxTitle{position:absolute; bottom:0px; left:0; text-align:center; width:100%; color:#999;}
        #cboxCurrent{position:absolute; bottom:0px; left:100px; color:#999;}
        #cboxLoadingOverlay{background:#fff url(../img/loading.gif) no-repeat 5px 5px;}

        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }
        
        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxSlideshow{position:absolute; bottom:0px; right:42px; color:#444;}
        #cboxPrevious{position:absolute; bottom:0px; left:0; color:#444;}
        #cboxNext{position:absolute; bottom:0px; left:63px; color:#444;}
#cboxClose {
	position: absolute;
	bottom: 0.8em;
	right: 1em;
	display: block;
	color: #444;
	font-size: 0;
}
#cboxClose::after {
	position: absolute;
	right: -0.4em;
	bottom: -0.8em;
	display: block;
	width: 2em;
	height: 2em;
	color: var(--accent-color);
	font-family: "Material Design Icons";
	font-size: 2.5rem;
	content: "\f0156";
	transition: color .35s ease-in-out;
}
#cboxClose:hover::after {
	color: darkred;
}
#cboxClose:focus::after,
#cboxClose:focus-within::after {
	outline: 0;
	outline-width: 0px;
}

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}

#cboxContent {
	/*width: 60%;*/
	/*height: 90%;*/
}
#cboxContent::after {
	content: "";
	width: 50%;
	height: 2em;
	display: block;
	position: absolute;
	bottom: 10px;
	background-image: var(--logo-image);
	background-repeat: no-repeat;
	background-size: auto 100%;
	left: 10px;
}
@media screen and (max-width: 820px) {
	#cboxContent {
		/*width: 90%;*/
		/*height: 90%;*/
		/*overflow-y: auto;*/
	}
	#cboxContent .contact-form {
		overflow-y: auto;
	}
	#cboxLoadedContent {
		overflow-y: auto;
	}
}