:root {
	--base-font-size: 16px;
	--text-font-family: 'Roboto', sans-serif;
	--base-text-color: #333333;
	--container-width: 90%;
	--container-max-width: 1412px;
	--container-mid-width: 846px;
	--section-color-construction: #ff9933;
	--section-color-aircon: #66cc99;
	--section-color-management: #3399cc;
	--padding-navbar-link: 1em;
	--accent-color: #003e9b;
	--button-primary-color: #0054a5;
	--light-text-color: #666666;
	font-size: var(--base-font-size);
	font-family: var(--text-font-family);
	color: var(--base-text-color);
}
.neutral {
	--accent-color: #666666;
	--accent-gradient-dark: #555555;
	--accent-gradient-lite: #777777;
	--segment-image: url("/assets/img/segment-construction.jpg");
	--hero-image: url("/assets/img/hero-about.jpg");
	--logo-image: url("/assets/img/csm-management.svg");
}
.construction {
	--accent-color: #ff9933;
	--accent-gradient-dark: #fb581d;
	--accent-gradient-lite: #f8ab30;
	--segment-image: url("/assets/img/segment-construction.jpg");
	--hero-image: url("/assets/img/hero-construction.jpg");
	--logo-image: url("/assets/img/csm-construction.svg");
}
.page-construction {
	--logo-image: url("/assets/img/csm-construction.svg");
}
.aircon {
	--accent-color: #66cc99;
	--accent-gradient-dark: #11998e ;
	--accent-gradient-lite: #38ef7d;
	--segment-image: url("/assets/img/segment-aircon.jpg");
	--hero-image: url("/assets/img/hero-aircon.jpg");
	--logo-image: url("/assets/img/csm-aircon.svg");
}
.page-aircon {
	--logo-image: url("/assets/img/csm-aircon.svg");
}
.about {
	--accent-color: #3399cc;
	--accent-gradient-dark: #003e9b;
	--accent-gradient-lite: #36baff;
	--segment-image: url("/assets/img/segment-management.jpg");
	--hero-image: url("/assets/img/hero-management.jpg");
	--logo-image: url("/assets/img/csm-management.svg");
}
.page-about {
	--logo-image: url("/assets/img/csm-management.svg");
}
.management {
	--accent-color: #3399cc;
	--accent-gradient-dark: #003e9b;
	--accent-gradient-lite: #36baff;
	--segment-image: url("/assets/img/segment-management.jpg");
	--hero-image: url("/assets/img/hero-management.jpg");
	--logo-image: url("/assets/img/csm-management.svg");
}
.management .project {
	overflow-x: auto;
}
.page-management {
	--logo-image: url("/assets/img/csm-management.svg");
}
.contact {
	--accent-color: #3399cc;
	--accent-gradient-dark: #003e9b;
	--accent-gradient-lite: #36baff;
	--segment-image: url("/assets/img/segment-management.jpg");
	--hero-image: url("/assets/img/hero-contact.jpg");
	--logo-image: url("/assets/img/csm-management.svg");
}
.social {
	display: flex;
	align-items: center;
}
.linkedin {
	--accent-color: #0054a6;
	display: inline-block;
	height: 30px;
	width: 30px;
	background-color: var(--accent-color);
	-webkit-mask-image: url(/assets/img/icons/linkedin.svg);
	mask-image: url(/assets/img/icons/linkedin.svg);
	-webkit-mask-size: 100%;
	mask-size: 100%;
}
* {
	box-sizing: border-box;
}
p, li {
	font-weight: 300;
	line-height: 1.5;
}
blockquote {
	font-size: 24px;
	font-weight: 200;
	max-width: var(--container-mid-width);
	margin: auto;
	padding: 1em;
	line-height: 1.4;
}
main li {
	list-style: "\2013 \00a0";
}
h1 {
	font-size: 4.5rem;
}
h4 {
	padding: 0;
	margin: 1em 0 0.3em;
}
h4 + p {
	margin: 0 0 1em;
}
a {
	position: relative;
	color: var(--base-text-color);
	text-decoration: none;
}
a::before {
	content: "";
	display: block;
	height: 2px;
	position: absolute;
	bottom: -.1em;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.05);
	transition: all .3s ease;
}
a::after {
	content: "";
	display: block;
	height: 2px;
	position: absolute;
	bottom: -.1em;
	left: 0;
	right: calc(100% - 0em);
	right: 100%;
	background-color: var(--accent-color);
	transition: all .3s ease;
}
a:hover::after,
a:focus::after {
	left: 0;
	right: 0;
}
#nav-toggle {
	display: none;
}
#toggler {
	display: none;
}
header {
	position: relative;
	min-height: 30rem;
	background-image: linear-gradient(to bottom, rgba(255,255,255,0) 70%,rgba(255,255,255,1) 100%), var(--hero-image);
	background-repeat: no-repeat;
	background-position: right 5rem;
	background-size: 100% auto;
	/*background-size: cover;*/
}
header::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	display: block;
	/* background-color: white; */
	background: linear-gradient(180deg, rgba(255,255,255,0) 74%, rgba(255,255,255,1) 100%);
	z-index: 1;
}
header .sub-logo {
	height: 9.9em;
	margin-bottom: 2em;
}
header .intro svg {
	height: 10em;
	margin-bottom: 0em;
}
.page-hp header {
	background-image: linear-gradient(to bottom, rgba(255,255,255,0) 70%,rgba(255,255,255,1) 100%), url("/assets/img/hero-hp.jpg");
	background-position: center top;
}
.page-hp main,
.page-about main {
	 min-height: 30vh;
}
nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: var(--container-max-width);
	margin: auto;
	height: 5rem;
}
header .navbar {
	transition: all .4s ease;
	z-index: 5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}
header .navbar.fixed {
	/*width: 100%;*/
	background: white;
	box-shadow: 0 3px 20px -15px black;
	transition: all .69s ease;
}
header .logo {
	height: 4rem;
	width: 4rem;
	background-image: url("/assets/img/csm.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}
header .logo a {
	display: block;
	width: 100%;
	height: 100%;
	font-size: 0;
	text-indent: -99999px;
}
header .logo a::before,
header .logo a::after {
	content: none;
}
header .language span,
header .language a {
	/*display: inline-block;*/
	/*line-height: 3;*/
	background-size: 1.6em;
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 2em;
}
header .language span {
	filter: grayscale(100%) brightness(200%);
}
#lang-en span,
#lang-en a {
	background-image: url("/assets/img/icons/flag-uk-128.png");
}
#lang-hr span,
#lang-hr a {
	background-image: url("/assets/img/icons/flag-hr-128.png");
}
nav .language ul li a:focus::after,
nav .language ul li a:hover::after {
	left: 2em;
	right: 1em;
}
nav ul {
	display: flex;
	list-style: none;
	margin: auto;
	line-height: 3;
}
nav ul li {
	/*margin-right: 2em;*/
}
nav ul li:last-child {
	margin-right: 0;
}
nav ul li > span,
nav ul li a {
	position: relative;
	color: var(--base-text-color);
	padding: var(--padding-navbar-link);
	text-decoration: none;
}
nav ul li a::before {
	content: none;
}
nav ul li a::after {
	content: "";
	display: block;
	height: 2px;
	position: absolute;
	bottom: 0.5em;
	left: 50%;
	right: 50%;
	background-color: var(--accent-color);
	transition: all .3s ease;
	z-index: 3;
}
nav ul li a:focus::after,
nav ul li a:hover::after {
	left: 1em;
	right: 1em;
}
.hero {
	position: relative;
	display: flex;
	justify-content: space-between;
	max-width: var(--container-max-width);
	margin: auto;
	padding: 15em 0;
	z-index: 3;
}
.page-contact .hero {
	/*max-width: calc(var(--container-max-width) - 236px);*/
	max-width: calc(var(--container-max-width) - 350px);
}
.page-hp .hero {
	position: relative;
	/*background-image: url("/assets/img/croatia.svg");*/
	/*background-repeat: no-repeat;*/
	/*background-position: 45% center;*/
	/*background-size: auto 80%;*/
	z-index: 2;
}
.page-hp .hero::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-image: url("/assets/img/croatia.svg");
	background-repeat: no-repeat;
	background-position: 45% center;
	background-size: auto 80%;
	filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.25));
	z-index: -1;
}
.hero .cta {
	width: 15em;
	align-self: flex-end;
	margin-right: 3em;
}
.page-about .hero {
	padding-bottom: 5em;
}
.page-about .hero .cta {
	align-self: center;
}
.page-aircon .hero {
	padding-bottom: 5em;
}
.hero .cta a {
	position: relative;
	display: inline-block;
	padding: .5em 3em.5em 1em;
	font-size: 1.3rem;
	color: white;
	background-color: var(--accent-color);
	background: linear-gradient(45deg, var(--accent-gradient-dark) 0%, var(--accent-gradient-lite) 100%);
	border: 1px solid var(--accent-color);
	border-radius: 1em;
	overflow: hidden;
	transition: transform .35s ease-in;
	z-index: 5;
}
.hero .cta a:hover {
	/*transform: scale(1.01);*/
}
.hero .cta a::before {
	content: "\f0054";
	position: absolute;
	top: 50%;
	bottom: auto;
	left: auto;
	right: 1em;
	height: auto;
	color: var(--accent-color);
	background-color: white;
	padding: .5em;
	border-radius: 50%;
	font-size: 1rem;
	transform: translateY(-1em);
	font-family: "Material Design Icons";
}
.hero .cta a::after {
	content: '';
	position: absolute;
	top: -50%;
	right: -50%;
	bottom: -150%;
	left: -150%;
	background: linear-gradient(to bottom,
								rgba(142, 192, 229,0) 0%,
								/*rgba(142, 192, 229,0),*/
								rgba(255,255,255,.5) 50%,
								/*rgba(142, 192, 229, 0),*/
								rgba(142, 192, 229, 0) 100% );
	transform: rotateZ(60deg) translate(-5em, 8em) scale(150);
	z-index: 5;
}
.hero .cta a:focus::after,
.hero .cta a:hover::after {
	-webkit-animation: sheen 1s forwards;
	animation: sheen 1s forwards;
}
@-webkit-keyframes sheen {
	100% {
		transform: rotateZ(60deg) translate(1em, -19em);
	}
}
@keyframes sheen {
	100% {
		transform: rotateZ(60deg) translate(1em, -19em);
	}
}

.hero .cta a span {
	display: block;
	width: 10em;
	text-align: left;
}
.hero .cta a span:first-child {
	font-size: 80%;
	font-weight: 300;
}
.hero .intro {
	width: 38em;
	align-self: flex-end;
}
.page-contact .hero .intro {
	/*width: 39em;*/
	transform: translateX(-160px);
}
.page-hp .hero .intro {
	/*margin-bottom: 2.5em;*/
}
.hero .intro h3 {
	font-size: 24px;
}
.hero .intro p {
	font-size: 18px;
	font-weight: 300;
}
.hero .intro ol {
	padding: 0 0 0 1em;
}
.hero .intro p.read-more {
	text-align: right;
	font-weight: 500;
	color: var(--accent-color);
	margin-bottom: -1em;
	margin-top: 3em;
}
.hero .intro p.read-more a {
	color: var(--accent-color);
}
.hero.contact {
	padding-top: 5rem
}
main {
	/*min-height: 30vh;*/
}
section {
	width: var(--container-width);
	max-width: var(--container-max-width);
	margin: auto;
}
.homepage ul {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	display: flex;
	justify-content: space-between;
	column-gap: 1em;
}
.homepage ul li {
	position: relative;
	height: 220px;
	/*width: 450px;*/
	width: 50%;
	/*flex-grow: 1;*/
	/*flex-basis: 0;*/
	display: flex;
	align-items: center;
	padding: 0 2em;
	background-repeat: no-repeat;
	background-position: right center;
	border-bottom: 1px solid var(--accent-color);
	border-radius: 1em;
	overflow: hidden;
	transition: all .6s ease;
}
.homepage ul li:hover {
}
.homepage ul li::before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: "";
	background-image: var(--segment-image);
	background-repeat: no-repeat;
	background-position: right center;
	background-size: cover;
	z-index: 0;
	transition: all .6s ease;
}
.homepage ul li:hover::before {
	filter: grayscale(100%) brightness(1.2) blur(4px);
}
.homepage ul li::after {
	content: "\f0054";
	position: absolute;
	bottom: 1em;
	right: 1em;
	color: white;
	background-color: var(--accent-color);
	padding: .5em;
	border-radius: 50%;
	font-size: 1.1rem;
	line-height: 1;
	font-family: "Material Design Icons", monospace;
}
.homepage ul li h3 {
	font-size: 23px;
	/*width: 6.7em;*/
	z-index: 1;
	color: var(--accent-color);
}
.homepage ul li p {
	position: absolute;
	bottom: -4em;
	width: 15em;
	line-height: 1.1;
	transition: all .6s ease;
}
.homepage ul li:hover p {
	bottom: .71em;
}
.homepage ul li a {
	position: absolute;
	display: inline-block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
}
.gallery {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-areas:
	"status image"
	"title image"
	"task image"
	"stats image"
	"description image";
	margin-bottom: 3em;
}
.gallery h4 {
	/*margin-bottom: 0;*/
	padding: 0;
}
.gallery p {
	margin-top: 0;
	padding: 0;
}
.gallery > * {
	margin-left: 120px;
}
.construct-status {
	grid-area: status;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
}
.construct-title {
	grid-area: title;
	font-size: 44px;
	margin-top: 0;
	margin-bottom: 0;
}
.construct-title::after {
	content: "";
	display: block;
	height: 1px;
	width: calc(50% + 120px);
	background-color: var(--accent-color);
	bottom: 0;
	margin-left: -120px;
}
.construct-task {
	grid-area: task;
}
.construct-stats {
	grid-area: stats;
	display: flex;
	justify-content: space-between;
}
.construct-stats p {
	color: var(--accent-color);
	font-size: 130%;
}
.construct-description {
	grid-area: description;
	text-align: justify;
}
.construct-gallery {
	grid-area: image;
	width: 568px;
	height: 524px;
	margin-left: 148px;
	border: 0px solid white;
	border-radius: 15px;
	box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.5);
	overflow: hidden;
}
.construct-gallery a img {
	width: 548px;
}

section > .segments {
	display: flex;
	padding: 0;
	list-style: none;
	justify-content: space-between;
	column-gap: 1em;
}
section > .segments .segment {
	list-style: none;
	flex-grow: 1;
	flex-basis: 0;
}
.installation {
	--aircon-segment: url("/assets/img/aircon/installation.jpg");
}
.design {
	--aircon-segment: url("/assets/img/aircon/system-design.jpg");
}
.sales {
	--aircon-segment: url("/assets/img/aircon/sales.jpg");
}
.segment h3 {
	position: relative;
	min-height: 8em;
	margin: 1em 0 0.3em;
	padding-top: 6em;
	color: var(--accent-color);
	font-size: 1.6rem;
	text-indent: .95em;
	line-height: 2;
	background-image: linear-gradient(5deg, rgb(255, 255, 255) 13%, rgba(255,255,255,0) 80%), var(--aircon-segment);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% auto;
	border-bottom: 1px solid var(--accent-color);
	border-radius: .5em;
	cursor: default;
}
.segment ul {
	padding: 0 0 0 1.6em;
}
.segment li {
	/*list-style: "\2013 \00a0";*/
	list-style: "\2713 \00a0";
}
.download {
	text-align: center;
	margin: 3em auto 5em;
}
.download a {
	display: inline-block;
	border: 1px solid #cccccc;
	padding: 1em 1.5em 1em 4em;
	border-radius: .8em;
	margin: auto;
	transition: all 0.35s ease-in;
	overflow: hidden;
	min-width: 15em;
}
.download a:hover {
	color: white;
	background-color: var(--accent-color);
	background: linear-gradient(45deg, var(--accent-gradient-dark) 0%, var(--accent-gradient-lite) 100%);
	border: 1px solid var(--accent-color);
	/*transform: scale(1.01);*/
}

.download a span {
	display: block;
	text-align: left;
	transition: all .35s ease;
}
.download a span:first-child {
	color: #333333;
	font-size: 95%;
}
.download a span:last-child {
	color: #999999;
	font-size: 80%;
}
/*.download a:hover span:last-child,*/
.download a:hover span {
	color: white;
	transition: all .2s ease;
}
.download a::before {
	content: "";
	background: url("/assets/img/pdf-red.svg") no-repeat;
	height: 2em;
	width: 2em;
	left: 1.5em;
	top: 1em;
}
.download.call a::before {
	content: "";
	background: url("/assets/img/phone-call.svg") no-repeat;
	height: 2em;
	width: 2em;
	left: 1.5em;
	top: 1em;
}
/*.download a::after {
	content: none;
}*/
.download a::after {
	content: '';
	position: absolute;
	top: -50%;
	right: -50%;
	bottom: -150%;
	left: -150%;
	background: linear-gradient(to bottom,
	rgba(142, 192, 229,0) 0%,
		/*rgba(142, 192, 229,0),*/
	rgba(255,255,255,.5) 50%,
		/*rgba(142, 192, 229, 0),*/
	rgba(142, 192, 229, 0) 100% );
	transform: rotateZ(60deg) translate(-5em, 7.5em) scale(100);
	z-index: 5;
	/*background-blend-mode: difference;*/
}
/*.download a:focus::after,
.download a:hover::after {
	animation: sheen .8s forwards;
}*/

.download a:hover::after,
.download a:focus::after {
	-webkit-animation: sheen 1s forwards;
	animation: sheen 1s forwards;
}
@-webkit-keyframes sheen {
	100% {
		transform: rotateZ(60deg) translate(1em, -19em);
	}
}
@keyframes sheen {
	100% {
		transform: rotateZ(60deg) translate(1em, -19em);
	}
}
section.points {
	max-width: var(--container-mid-width);
	margin: auto;
}
.points ul {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;
	list-style: none;
}
.points ul li {
	display: inline-block;
	width: calc(50% - 1em);
	margin: 1em 1em 1em 0;
}
.points ul li:nth-child(even) {
	margin: 1em 0 1em 1em;

}
.points ul li h3 {
	padding-left: 3em;
	font-size: 1.5rem;
	max-width: 15em;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: auto 75%;
	min-height: 3em;
	display: flex;
	align-items: center;
}
.points ul li:nth-child(1) h3 {
	background-image: url("/assets/img/icons/icon-analysis.svg");
}
.points ul li:nth-child(2) h3 {
	background-image: url("/assets/img/icons/icon-proposals.svg");
}
.points ul li:nth-child(3) h3 {
	background-image: url("/assets/img/icons/icon-business-plans.svg");
}
.points ul li:nth-child(4) h3 {
	background-image: url("/assets/img/icons/icon-implementation.svg");
}
.points ul li p {
	font-size: 1.2rem;
	/*max-width: 16em;*/
}


.scroll-table {
	margin: 3rem auto;
	overflow-x: auto;
	scrollbar-color: var(--accent-color) transparent;
	scrollbar-width: thin;
	scrollbar-height: thin;
}
.scroll-table::-webkit-scrollbar {
	width: 0.1rem;
	height: 0.2rem;
}
.scroll-table::-webkit-scrollbar-track {
	background: transparent;
}
.scroll-table::-webkit-scrollbar-thumb {
	background: var(--accent-color);
}
table {
	width: 100%;
	color: var(--base-text-color);
	border-collapse: collapse;
	box-shadow: 0 0 35px -10px rgba(0, 0, 0, 0.1);
}
table caption {
	color: white;
	background-color: var(--accent-color);
	background-color: #136abe;
	height: 5em;
	/* vertical-align: middle; */
	/* padding: 1.5em; */
	line-height: 5em;
}
table th,
table td {
	padding: 0 2em;
	font-weight: 300;
}
table thead th {
	/*height: 5em;*/
	line-height: 5em;
	font-weight: normal;
	color: #136abe;
	background-color: #eef9ff;
	white-space: nowrap;
	font-variant: all-small-caps;
}
table tbody td {
	height: 6em;
	display: table-cell;
	vertical-align: middle;
}
table th.no,
table td.no {
	text-align: center;
	padding: 0 0 0 1em;
}
table th.applicant,
table td.applicant {
	text-align: left;
}
table th.partnership,
table td.partnership {
	text-align: left;
}
table th.program,
table td.program {
	text-align: left;
}
table th.project,
table td.project {
	text-align: left;
}
table th.value,
table td.value,
table th.finance,
table td.finance {
	text-align: right;
	white-space: nowrap;
}
table th.status,
table td.status {
	/*text-align: center;*/
	text-align: right;
	white-space: nowrap;
	width: 10em;
}
table td.status {
	white-space: normal;
}
table th.measure,
table td.measure {
	text-align: left;
}
table th.center,
table td.center {
	text-align: center;
}
table th.left,
table td.left {
	text-align: left;
}
table th.right,
table td.right {
	text-align: right;
}
table tr {
	border-bottom: 1px solid #d5e1e7;
}

tr.approved .status {
	color: #33cc33;
}
tr.in-process .status {
	color: #3399cc;
}
tr.contracted .status {
	color: #7C7C7C;
}
tr.declined .status {
	color: #22bb22;
}

.footer-top {
	display: flex;
	justify-content: space-between;
	width: var(--container-width);
	max-width: var(--container-max-width);
	margin: auto;
	border-top: 1px solid #e3e3e3;
	padding-top: 35px;
	padding-bottom: 35px;
}
.plaque {
	display: flex;
}
.plaque img {
	width: 90px;
	height: 120px;
	margin-right: 2em;
}
.plaque .company-name {
	font-size: 18px;
}
.plaque .company-address {
	font-size: 13px;
}
.certs {
	width: 300px;
}
.certs img {
	width: 300px;
	height: 50px;
}
.footer-bottom {
	display: flex;
	justify-content: space-between;
	max-width: var(--container-width);
	margin: auto;
	font-size: 14px;
	line-height: 5;
}
.footer-bottom .copyright span:first-child::after {
	content: " | ";
}
.social img {
	fill: var(--accent-color);
}
.company-contact {
	/*max-width: 33rem;*/
	max-width: 30rem;
	font-size: 18px;
}
.company-contact h3 {
	font-size: 24px;
}
.company-contact svg {
	margin: 0;
	padding: 0;
}
.contact-person {
	display: flex;
	justify-content: space-between;
}
.contact-form {
	align-self: flex-end;
}
.contact-form form {
	display: flex;
	flex-direction: column;
	width: 455px;
}
.floating-label {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
}
/*.floating-label label {
	position: absolute;
	top: 1.65em;
	left: 1.1em;
	transition: all .3s ease;
}*/
.floating-label input::placeholder {
	color: var(--light-text-color);
}
.floating-label input ~ label,
.floating-label input + label,
.floating-label select + label,
.floating-label textarea + label {
	position: absolute;
	top: 1.65em;
	left: 1.1em;
	transition: all .3s ease;
	opacity: 0;
}

.floating-label input:not(:placeholder-shown) ~ label,
.floating-label input:not(:placeholder-shown) + label,
.floating-label textarea:not(:placeholder-shown) + label {
	color: var(--light-text-color);
	font-size: 80%;
	transform: translate3d(5%, -75%, 0);
	opacity: 1;
}

.contact-form input[type=text],
.contact-form input[type=email],
.contact-form input[type=tel],
.contact-form button,
.contact-form select,
.contact-form textarea {
	width: 100%;
	margin: .5em 0;
	padding: 1em;
	border: 1px solid rgba(109, 109, 109, 0.2);
	border-radius: .7em;
}
.contact-form input:focus,
.contact-form button:focus,
.contact-form select:focus,
.contact-form textarea:focus {
	outline: none;
	box-shadow: 0 0 30px -20px rgba(0, 0, 0, 0.975);
}
.contact-form textarea {
	min-height: 11em;
}
.contact-form button {
	color: white;
	background-color: var(--button-primary-color);
	cursor: pointer;
}
.contact-form select {
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	z-index: 1;
}
.contact-form select::after {
	content: "";
	position: absolute;
	display: block;
	height: 1em;
	width: 1em;
	background-color: black;
	right: 1em;
	transform: rotate(-45deg);
	border-left: 1px solid #999999;
	border-bottom: 1px solid #999999;
	z-index: 5;
}
.grecaptcha-badge {
	visibility: hidden;
	opacity: 0;
}
.contact-form #agreed {
	margin-left: 0.2em;
}
footer .contact-form {
	display: none;
}
.legal {
	padding: 1em;
	font-size: 1.2rem;
	word-break: break-word;
	font-weight: 300;
}
.legal h1 {
	font-weight: 500;
	font-size: 3rem;
}
.legal h2 {
	font-weight: 500;
	font-size: 1.6rem;
	text-transform: uppercase;
}
.legal h3 {
	font-weight: 500;
}
.legal h4 {
}
.legal p {
}
.legal ul {
	padding-left: 2em;
}
.legal li {
	list-style-type: "\2013 \00a0";
}

@media screen and (max-width: 1410px) {
	.contact .hero .intro {
		transform: translateX(-70px) scale(0.8);
	}
}
@media screen and (max-width: 1100px) {
	.contact .hero {
		flex-direction: column;
	}
}

@media screen and (max-width: 896px) and (orientation: landscape),
	   screen and (max-width: 768px) {
	:root {
		--container-width: 90vw;
		--container-max-width: 90vw;
		--container-mid-width: 90vw;
	}
	/*

	th.no {
		font-size: 0;
	}
	td.no {
		!* padding: 0; *!
		font-size: 3em;
		text-indent: -0.3em;
		overflow: visible;
		position: absolute;
		line-height: 2;
		!* font-family: "Open Sans Condensed", sans-serif; *!
		font-weight: 300;
		text-align: right;
		font-stretch: extra-condensed;
	}
	table th.status,
	table td.status {
		text-align: center;
		white-space: nowrap;
		padding: 0;
		font-size: 0;
	}
	td.status::after {
		font-size: 2rem;
		content: "\F012C";
		font-family: "Material Design Icons", monospace;
		position: absolute;
		display: block;
		right: 0px;
		line-height: 0px;
		margin-right: -0.2em;
		text-shadow:
				0 3px 0em white,
				0 -3px 0em white,
				3px 0 0em white,
				-3px 0 0em white,
				-3px -3px 0em white,
				3px -3px 0em white,
				-3px 3px 0em white,
				3px 3px 0em white,
				0 0 1em white;
	}
	tr.approved .status::after {
		content: "\F05E0";
	}
	tr.in-process .status::after {
		content: "\F0B2B";
	}
	tr.contracted .status::after {
		content: "\F0419";
	}
	tr.declined .status::after {
		content: "\F015A";
	}
	*/

	h1 {
		font-size: 2rem;
	}
	.hero {
		display: flex;
		flex-direction: column;
		padding: 9em 0 3em;
	}
	.hero > *,
	.page-contact .hero .intro {
		width: var(--container-width) !important;
		transform: none;
	}
	header {
		background-position: right 4rem;
	}
	header .sub-logo {
		width: var(--container-width);
		height: auto;
		margin-bottom: 2em;
	}
	header nav {
		height: 4rem;
	}
	header .logo {
		/*height: 2.5rem;*/
		height: 40px;
		/*width: 8rem;*/
		width: 128px;
		background-image: url("/assets/img/csm-management.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
	}
	.hero .cta {
		margin: 0;
	}
	.page-hp header {
		background-position: center -2rem;
		background-size: 180%;
	}
	.page-hp .hero::before {
		background-position: center 15%;
		background-size: 95% auto;
		left: -4vw;
		right: -4vw;
	}
	.page-hp .hero h1 {
		margin-top: 6.5rem;
		font-size: 2.22rem;
	}
	.hero .intro svg {
		margin-top: -2.3em;
	}
	.hero .intro p.read-more {
		text-align: center;
		margin: 3em auto 0;
	}
	.page-contact .hero {
		max-width: unset;
	}
	.contact-person {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	.contact-form form {
		/*width: var(--container-width);*/
		width: auto;
	}
	.contact-form input[type=text],
	.contact-form input[type=email],
	.contact-form input[type=tel],
	.contact-form select,
	.contact-form textarea {
		border: 1px solid var(--accent-color);
	}
	.homepage ul {
		display: flex;
		flex-direction: column;
	}
	.homepage ul li {
		width: 90vw;
		margin: 1em auto;
	}
	.gallery {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas: "status" "title" "task" "image" "stats" "description";
		margin-bottom: 3em;
	}

	.gallery > * {
		margin-left: 0;
	}
	.construct-gallery {
		width: 90vw;
		height: calc((90vw / 4 * 3) + 41px);
	}
/*	.construct-gallery .galleria-stage {
		height: calc((90vw / 4 * 3) + 40px);
	}*/
	.construct-stats {
		flex-direction: column;
	}
	section > .segments {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.points ul li {
		width: 100%;
		margin: 0;
	}
	.points ul li:nth-child(2n) {
		margin: 0;
	}
/*	.points ul li h3 {
		padding-left: 0;
		font-size: 1.2rem;
		min-height: 5em;
		background-position: center 0em;
		background-size: 2em;
		padding-top: 2em;
		margin: 0;
	}*/
	.footer-top {
		flex-direction: column;
	}
	.footer-top nav {
		height: auto;
		order: 3;
	}
	.footer-top nav ul {
		flex-direction: column;
		padding: 0;
	}
	.footer-top nav ul li {
		text-align: center;
		padding: 1em;
	}
	.certs {
		width: 90vw;
		margin: 1rem auto;
	}
	.certs img {
		width: 90vw;
		height: auto;
	}
	.footer-bottom {
		flex-direction: column;
		text-align: center;
	}
	.footer-bottom > * {
		line-height: 3;
	}
	.footer-bottom span {
		display: block;
	}
	.footer-bottom .copyright span:first-child::after {
		content: none;
	}
	.footer-bottom .social {
		flex-direction: column;
		order: 3;
		text-align: center;
		margin: 2em auto;
	}
	.footer-bottom .links span {
		display: block;
	}

	/* main navigation */
	#nav-toggle:checked ~ ul {
		padding-top: 1em;
		max-height: calc(100vh - 3.2em);
		border-top: 1px solid var(--accent-color);
		overflow-y: auto;
		box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
		transition: all .4s ease;
	}
	#nav-toggle:checked ~ ul li {
		width: 100vw;
	}
	#nav-toggle:checked ~ ul li a {
		padding: 0 5vw;
		display: block;
	}
	#toggler {
		position: absolute;
		display: block;
		width: 2em;
		height: 2em;
		background-color: var(--accent-color);
		background: linear-gradient(0deg, transparent 0%, transparent .85em, var(--accent-color) .85em, var(--accent-color) 1.15em, transparent 1.15em, transparent 100%);;
		cursor: pointer;
		top: 1em;
		right: 5vw;
		transition: .4s ease;
	}
	#toggler::before {
		content: "";
		position: absolute;
		top: .2em;
		display: block;
		width: 2em;
		height: 4px;
		background-color: var(--accent-color);
		transition: .4s ease;
	}
	#toggler::after {
		content: "";
		position: absolute;
		bottom: .2em;
		display: block;
		width: 2em;
		height: 4px;
		background-color: var(--accent-color);
		transition: .4s ease;
	}
	#nav-toggle:checked + #toggler {
		height: 2em;
		background: transparent;
		transition-delay: 1.5s;
	}
	#nav-toggle:checked + #toggler::before {
		transform: translateY(0.666em) rotate(135deg);
	}
	#nav-toggle:checked + #toggler::after {
		transform: translateY(-0.666em) rotate(225deg);
	}

	header nav > ul {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		line-height: 3;
		flex-direction: column;
		position: absolute;
		top: 4em;
		left: 0;
		right: 0;
		max-height: 0;
		overflow-y: hidden;
		background-color: #ffffff;
		z-index: 5;
	}
	header nav ul li {
		position: relative;
		font-size: 1.2rem;
		line-height: 3;
	}
	header nav ul li a::before {
		display: none;
	}
	header nav ul li a:hover::after {
		display: none;
	}
	header nav ul li:hover ul {
		display: inherit;
		overflow: hidden;
	}
	header nav ul a:hover ~ ul {
		display: inherit;
		overflow: hidden;
	}
	heanav ul input {
		display: none;
	}
	nav ul label {
		position: absolute;
		right: .3em;
		top: 0.5em;
		width: 2.2em;
		height: 1.4em;
		font-size: 1.5rem;
		border-left: 1px solid var(--accent-color);
		z-index: 55;
		cursor: pointer;
	}
	nav ul label::before {
		content: "\2014";
		position: absolute;
		right: .6em;
		top: -0.4em;
		display: block;
		color: var(--accent-color);
		transition: all 0.4s;
	}
	nav ul label::after {
		content: "\2014";
		position: absolute;
		right: .6em;
		top: -0.4em;
		display: block;
		color: var(--accent-color);
		transition: transform 0.4s ease-in,
		opacity 0.1s 0.1s ease;
		transform: rotate(-90deg);
		opacity: 1;
	}
	nav ul input[type=checkbox]:checked + label::after,
	nav ul input[type=radio]:checked + label::after {
		right: .6em;
		top: -0.4em;
		transition: transform 0.4s ease,
		opacity 0.7s 0.2s ease,
		right 0.1s 0.1s ease;
		transform: rotate(0deg);
		opacity: 0;
	}
	.language {
		max-height: 0;
		transition: all 0.1s;
		transition-delay: -0.1s;
		overflow: hidden;
	}
	#nav-toggle:checked ~ .language {
		display: flex;
		position: absolute;
		/*bottom: -22.5em;*/
		bottom: -19.12em;
		/* height: 3em; */
		background: white;
		z-index: 9;
		width: 100vw;
		left: 0;
		max-height: 4em;
		transition: all 0.4s;
		transition-delay: 0.1s;
		box-shadow: 0 10px 20px -20px black;
		border-top: 1px solid var(--accent-color);
	}

	.legal ul {
		padding-left: 1em;
	}

	section > .segments .segment {
		list-style: none;
		flex-grow: 1;
		flex-basis: 1;
	}

}
@media screen and (max-width: 896px) and (orientation: landscape) {
/*	.homepage ul {
		flex-direction: row;
	}*/
	.homepage ul li::before {
		background-image: linear-gradient(90deg, rgba(255,255,255,1) 39%, rgba(255,255,255,0) 50%), var(--segment-image);
	}
	.points ul li {
		display: inline-block;
		width: calc(50% - 1em);
		margin: 1em 1em 1em 0;
	}
	.points ul li:nth-child(2n) {
		margin: 1em 0 1em 1em;
	}
	.points ul li h3 {
		padding-left: 0;
		font-size: 1.2rem;
		min-height: 5em;
		background-position: center 0em;
		background-size: 2em;
		padding-top: 2em;
		margin: 0;
	}
}
@media screen and (max-width: 768px) and (orientation: portrait) {
	.hero .cta a {
		display: block;
	}
	.mfp-container {
		position: fixed;
	}
}

.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #FFFFFF;
	opacity: 0.9;
}

.mfp-close-btn-in .mfp-close {
	color: var(--accent-color);
	background: transparent;
	font-size: 4rem;
	font-weight: 300;
	line-height: 1;
	margin: -0.2em 0 0;
	padding: 0 !important;
	width: .6em;
	height: 1em;
	box-shadow: none;
	position: relative;
	border: none;
	/* bottom: 0; */
	align-self: flex-end;
}
.white-popup {
	position: relative;
	background: #FFF;
	padding: 10px;
	width: auto;
	max-width: 475px;
	display: flex;
	margin: 0px auto;
	flex-direction: column;
	box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.69);
	transition: all .9s ease-in;
}
.white-popup::after {
	content: "";
	position: absolute;
	left: 1em;
	bottom: 1em;
	display: block;
	width: 50%;
	height: 2.5em;
	background-image: var(--logo-image);
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: auto 100%;
	/*transition: all .5s ease;*/
}